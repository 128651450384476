<template>
  <div class="classifydetail">
    <Header></Header>
    <main class="layout content-inner">
      <div id="page">
        <div class="article-sort-title">分类 - {{ pageResults[1] }}</div>
        <div class="article-sort" ref="articleContent">
          <div
            class="article-sort-item"
            v-for="(item, index) in pageResults[0]"
            :key="index"
            @click="go(item)"
          >
            <a class="article-sort-item-img">
              <img :src="item.cover" />
            </a>
            <div class="article-sort-item-info">
              <div class="article-sort-item-time">
                <i class="far fa-calendar-alt"></i>
                <time class="post-meta-date-created">{{
                  item.createtime
                }}</time>
              </div>
              <a class="article-sort-item-title">{{ item.title }}</a>
            </div>
          </div>
        </div>
      </div>
      <AsideContent></AsideContent>
    </main>
    <Footer></Footer>
    <ToTop></ToTop>
  </div>
</template>

<script>
import Header from "@/components/Header";
import AsideContent from "@/components/AsideContent";
import Footer from "@/components/Footer";
import ToTop from "@/components/ToTop";
export default {
  name: "ClassifyList",
  data() {
    return {
      pageResults: "",
    };
  },
  components: {
    Header,
    AsideContent,
    Footer,
    ToTop,
  },
  methods: {
    getDetail() {
      this.pageResults = JSON.parse(localStorage.getItem("blogpagelists"));
      // console.log(this.pageResults[0]);
      if (this.pageResults[0].length===0 ) {
        this.$refs.articleContent.innerHTML =
          "<a href='https://backblog.likepoems.com/writeblogs' style='font-size:1.5em;'>请前往后台添加文章内容!</a>";
      }
    },
    go(data) {
      let pagetitle = data.title.replace(/\s*/g, "");
      this.$router.push(`/pages/title=${pagetitle}`);
      localStorage.setItem("blogdetails", JSON.stringify(data));
    },
  },
  mounted() {
    this.getDetail();
  },
};
</script>

<style scoped>
.Header {
  height: 60vh;
  background-image: url("https://blog.likepoems.com/images/5.png");
}
.content-inner {
  animation: main 1s;
}
.layout {
  display: flex;
  margin: 0 auto;
  padding: 2rem 15px;
  max-width: 1200px
}
#page {
  width: 75%;
  align-self: flex-start;
  padding: 50px 40px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 8px 6px rgba(7, 17, 27, 0.06);
}
#page:hover {
  -webkit-box-shadow: 0 4px 12px 12px rgba(7, 17, 27, 0.15);
  box-shadow: 0 4px 12px 12px rgba(7, 17, 27, 0.15);
}

.article-sort-title {
  position: relative;
  margin-left: 0.5rem;
  padding-bottom: 1rem;
  padding-left: 1.3rem;
  font-size: 1.72em;
}
.article-sort-title:before {
  position: absolute;
  top: calc(((100% - 2.9rem) / 2));
  left: -0.58rem;
  z-index: 1;
  width: 0.8rem;
  height: 0.8rem;
  border: 0.25rem solid #49b1f5;
  border-radius: 0.8rem;
  background: #fff;
  content: "";
  line-height: 0.5rem;
  transition: all 0.2s ease-in-out;
}
.article-sort-title:after {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 0.15rem;
  height: 1.5em;
  background: #aadafa;
  content: "";
}
.article-sort {
  margin-left: 0.5rem;
  margin-bottom: 0.5em;
  padding-left: 1rem;
  border-left: 2px solid #aadafa;
}
.article-sort-item:before {
  position: absolute;
  left: calc(-1rem - 14.5px);
  width: 0.5rem;
  height: 0.5rem;
  border: 0.2rem solid #49b1f5;
  border-radius: 0.5rem;
  background: #fff;
  content: "";
  transition: all 0.2s ease-in-out;
}
.article-sort-title:hover:before,
.article-sort-item:hover:before {
  border-color: #ff7242;
}
.article-sort-item {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin: 0 0 1rem 0.5rem;
  transition: all 0.2s ease-in-out;
}
.article-sort-item-img {
  overflow: hidden;
  width: 80px;
  height: 80px;
}
.article-sort-item-img img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  object-fit: cover;
}
.article-sort-item-info {
  flex: 1;
  padding: 0 0.8rem;
}
.article-sort-item-time {
  color: #858585;
  font-size: 95%;
}
.article-sort-item-time time {
  padding-left: 0.3rem;
  cursor: default;
}
.article-sort-item-title {
  color: var(--font-color);
  font-size: 1.1em;
  transition: all 0.3s;
  -webkit-line-clamp: 2;
}
@media screen and (max-width: 768px) {
  .layout {
    flex-direction: column;
  }
  #page {
    width: 100%;
  }
}
</style>